import React, { useEffect } from "react";
import { Box, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMediaQuery } from "react-responsive";

const ScrollToTop = ({ theme }) => {
  const [scroll, setScroll] = React.useState(false);
  const [isTouchingFooter, setIsTouchingFooter] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 515px)" });

  useEffect(() => {
    // adding the event when scroll change Logo
    window.addEventListener("scroll", scrollVisibility);
  });

  const scrollVisibility = () => {
    if (!isTabletOrMobile) {
      if (window.scrollY >= 800) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
  };

  const handleScroll = () => {
    let footer = document.getElementById("footer-ID");
    let button = document.getElementById("scrollToTopButton");
    let buttonRect;
    let footerRect;
    if (footer != null && button != null) {
      footerRect = footer.getBoundingClientRect();
      buttonRect = button.getBoundingClientRect();

      if (
        buttonRect.top + window.scrollY >
        footerRect.top + window.scrollY - buttonRect.height
      ) {
        // The button is touching the footer, change the background color
        setIsTouchingFooter(true);
      } else {
        // The button is not touching the footer, reset the background color
        setIsTouchingFooter(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    scroll && (
      <Box>
        <Fab
          compoment="a"
          id="scrollToTopButton"
          href="#home"
          aria-label="add"
          sx={{
            backgroundColor: isTouchingFooter
              ? theme.colors.text.scrollColorOnFooter 
              : theme.colors.text.purple,
            color: isTouchingFooter ? "#5507a4" : "white",
            position: "fixed",
            bottom: "2.5rem",
            right: "2.5rem",
            "&:hover": {
              color: "#fff",
            },
          }}
        >
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "3.5rem",
            }}
          />
        </Fab>
      </Box>
    )
  );
};
export default ScrollToTop;
